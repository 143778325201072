import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from "pinia";
import { DamageCategory } from "@/types/processTypes";
import { useProcessStore } from "@/stores/process";
import FireWindSimulator from "@/components/FireWindSimulator.vue";
import WhatsMyRisk from "@/components/WhatsMyRisk.vue";
import AddressLookup from "@/components/AddressLookup.vue";
import ProcessResults from "@/components/ProcessResults.vue";
import MagicWindow from "@/components/MagicWindow.vue";

const routes = [
  {
    path: "/simulator/fire",
    name: "FireSim",
    component: FireWindSimulator,
    children: [
      {
        path: "address-lookup",
        name: "FireAddressLookup",
        component: AddressLookup,
      },
      {
        path: "results/:uuid",
        name: "FireSimulatorResults",
        component: ProcessResults,
      },
    ],
  },
  {
    path: "/simulator/wind",
    name: "WindSim",
    component: FireWindSimulator,
    children: [
      {
        path: "address-lookup",
        name: "WindAddressLookup",
        component: AddressLookup,
      },
      {
        path: "results/:uuid",
        name: "WindSimulatorResults",
        component: ProcessResults,
      },
    ],
  },
  {
    path: "/whats-my-risk",
    name: "WhatsMyRisk",
    component: WhatsMyRisk,
    children: [
      {
        path: "address-lookup",
        name: "WhatsMyRiskAddressLookup",
        component: AddressLookup,
      },
      {
        path: "results",
        name: "WhatsMyRiskResults",
        component: ProcessResults,
      },
    ],
  },
  {
    path: "/map",
    name: "MagicWindow",
    component: MagicWindow,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  const processStore = useProcessStore();
  const { damageCategory } = storeToRefs(processStore);

  if (to.matched[0].name === "FireSim") {
    damageCategory.value = DamageCategory.Fire;
  } else if (to.matched[0].name === "WindSim") {
    damageCategory.value = DamageCategory.Wind;
  } else {
    damageCategory.value = null;
  }
  next();
});

export default router;
