import { defineStore } from "pinia";
import { ref, Ref } from "vue";
import { MediaItem } from "@/types/mediaTypes";
import { DamageType, DamageCategory } from "@/types/processTypes";

export const useProcessStore = defineStore("process", () => {
  // State
  const damageType: Ref<DamageType | null> = ref(null);
  const damageCategory: Ref<DamageCategory | null> = ref(null);
  const submittedImage: Ref<string> = ref("");
  const readyForProcessing: Ref<boolean> = ref(false);
  const mediaItems: Ref<Array<MediaItem | null>> = ref(Array(0));
  const mediaItemIndex: Ref<number> = ref(0);
  const mediaGenerationInProgress: Ref<boolean> = ref(false);
  const enableAutoSlide: Ref<boolean> = ref(true);

  // Actions

  /**
   * Sets the readiness state for processing.
   *
   * @param {boolean} newreadyForProcessing - The new readiness state to set.
   */
  function setReadyForProcessing(newreadyForProcessing: boolean) {
    readyForProcessing.value = newreadyForProcessing;
  }

  /**
   * Sets the media items that are displayed in the results.
   *
   * @param {Array} newMediaItems - The new media items to set.
   */
  function setMediaItems(newMediaItems: Array<MediaItem | null>) {
    mediaItems.value = newMediaItems;
  }

  /**
   * Sets the index of the media item to be currently displayed in the results.
   *
   * @param {Array} newMediaItemIndex - The new index of the media item to set.
   */
  function setMediaItemIndex(newMediaItemIndex: number) {
    mediaItemIndex.value = newMediaItemIndex;
  }

  /**
   * Sets the media generation in-progress state.
   *
   * @param {Array} newMediaGenerationInProgress - The new progress state to set.
   */
  function setMediaGenerationInProgress(newMediaGenerationInProgress: boolean) {
    mediaGenerationInProgress.value = newMediaGenerationInProgress;
  }

  /**
   * Sets whether auto slide has been enabled or not.
   *
   * @param newEnableAutoSlide - The auto slide status
   */
  function setEnableAutoSlide(newEnableAutoSlide: boolean) {
    enableAutoSlide.value = newEnableAutoSlide;
  }

  return {
    damageType,
    damageCategory,
    submittedImage,
    readyForProcessing,
    mediaItems,
    mediaItemIndex,
    mediaGenerationInProgress,
    enableAutoSlide,
    setReadyForProcessing,
    setMediaItems,
    setMediaItemIndex,
    setMediaGenerationInProgress,
    setEnableAutoSlide,
  };
});
