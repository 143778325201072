<script setup lang="ts">
import { ref, computed, onMounted, watch, Ref, ComputedRef } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import api from "@/services/api";
import { useAppStore } from "@/stores/app";
import { useUserStore } from "@/stores/user.ts";
import { useProcessStore } from "@/stores/process";
import homePlaceholderImage from "@/assets/images/home_placeholder.png";

declare global {
  interface Window {
    initStreetView: () => void;
    google: any;
  }
}

const router = useRouter();
const route = useRoute();

const appStore = useAppStore();
const { isMobile } = storeToRefs(appStore);

const processStore = useProcessStore();
const { damageType, submittedImage, readyForProcessing } =
  storeToRefs(processStore);
const { setReadyForProcessing } = processStore;

const userStore = useUserStore();
const {
  streetAddress: savedStreetAddress,
  zipcode: savedZipcode,
  confirmedAddress,
} = storeToRefs(userStore);
const { setStreetAddress, setZipcode, setConfirmedAddress } = userStore;

let streetAddressInForm: Ref<string> = ref("");
let zipcodeInForm: Ref<string> = ref("");

let panorama: google.maps.StreetViewPanorama | null = null;
const latitude: Ref<number> = ref(0);
const longitude: Ref<number> = ref(0);
const heading: Ref<number> = ref(0);
const pitch: Ref<number> = ref(0);
const zoom: Ref<number> = ref(1);
const showStreetView: Ref<boolean> = ref(false);

const homeImage: ComputedRef<string> = computed(() => {
  return homePlaceholderImage;
});

watch(savedStreetAddress, (newValue: string) => {
  streetAddressInForm.value = newValue;
});

watch(savedZipcode, (newValue: string) => {
  zipcodeInForm.value = newValue;
});

/**
 * Looks up the user's address and displays it in Google Street View if found.
 * The user's address is also saved to local storage so that it persists
 * between refreshes.
 */
const lookupAddress = async () => {
  try {
    const response = await api.post("/lookup-address", {
      street_address: streetAddressInForm.value,
      zip_code: zipcodeInForm.value,
    });
    setConfirmedAddress(response.data.formatted_address);
    latitude.value = response.data.latitude;
    longitude.value = response.data.longitude;
    setReadyForProcessing(true);

    // Save the address and zipcode to local storage
    setStreetAddress(streetAddressInForm.value);
    setZipcode(zipcodeInForm.value);

    // Display street view
    showStreetView.value = true;
    initStreetView();
  } catch (error) {
    console.error("Error looking up address:", error);
    alert("Error looking up address. Please try again.");
  }
};

/**
 * Initialize Google Street View
 */
const initStreetView = () => {
  const streetViewDiv = document.getElementById("street-view");
  if (streetViewDiv && window.google && window.google.maps) {
    panorama = new window.google.maps.StreetViewPanorama(streetViewDiv, {
      position: { lat: latitude.value, lng: longitude.value },
      pov: { heading: heading.value, pitch: pitch.value },
      zoom: zoom.value,
    });

    panorama!.addListener("pov_changed", () => {
      const pov = panorama!.getPov();
      heading.value = pov.heading;
      pitch.value = pov.pitch;
    });

    panorama!.addListener("zoom_changed", () => {
      zoom.value = panorama!.getZoom();
    });
  }
};

/**
 * Using the street view, starts a simulation or risk evaluation by navigating
 * to the appropriate page.
 */
const startAddressProcessing = async () => {
  if (!panorama) {
    console.error("Street View panorama not initialized");
    return;
  }

  try {
    const position = panorama.getPosition();

    const response = await api.post("/submit-streetview", {
      latitude: position?.lat(),
      longitude: position?.lng(),
      heading: heading.value,
      pitch: pitch.value,
      fov: 180 / Math.pow(2, zoom.value),
      damage_type: damageType.value,
    });
    submittedImage.value = `data:image/jpeg;base64,${response.data.image}`;
    showStreetView.value = false;

    if (
      route.name === "FireAddressLookup" ||
      route.name === "WindAddressLookup"
    ) {
      router.push({
        name:
          route.name === "FireAddressLookup"
            ? "FireSimulatorResults"
            : route.name === "WindAddressLookup"
              ? "WindSimulatorResults"
              : "",
        params: { uuid: response.data.run_id },
        query: {
          dialogName: route.query?.dialogName,
        },
      });
    } else if (route.name === "WhatsMyRiskAddressLookup") {
      router.push({
        name: "WhatsMyRiskResults",
        query: {
          dialogName: route.query?.dialogName,
        },
      });
    }
  } catch (error) {
    console.error("Error submitting street view:", error);
    alert("Error submitting street view. Please try again.");
  }
};

onMounted(() => {
  window.initStreetView = initStreetView;
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBNx9avKFccciEomb7de2X2ZcvytnV3tPI&callback=initStreetView`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);

  streetAddressInForm.value = savedStreetAddress.value;
  zipcodeInForm.value = savedZipcode.value;
});
</script>

<template>
  <b-row class="mt-3">
    <b-col md="6">
      <h4>Address Lookup</h4>
      <div
        v-if="
          route.name === 'FireAddressLookup' ||
          route.name === 'WindAddressLookup'
        "
      >
        <div>Enter your address to begin the simulation.</div>
      </div>
      <div v-else-if="route.name === 'WhatsMyRiskAddressLookup'">
        <div>Enter your address to begin your risk evaluation.</div>
      </div>
      <b-form @submit.prevent="lookupAddress" class="mt-3">
        <!-- Street Address -->
        <b-form-group
          label="Street Address"
          label-for="street-address"
          label-class="fw-bold"
        >
          <b-form-input
            id="street-address"
            v-model="streetAddressInForm"
            placeholder="Enter your street address"
            required
          ></b-form-input>
        </b-form-group>

        <!-- Zip Code -->
        <b-form-group
          label="Zip Code"
          label-for="zip-code"
          label-class="fw-bold mt-2"
        >
          <b-form-input
            id="zip-code"
            v-model="zipcodeInForm"
            placeholder="Enter your zip code"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-row class="mt-3 mt-md-5 g-1">
          <b-col cols="4">
            <!-- Go Button -->
            <b-button
              type="submit"
              variant="secondary"
              class="text-start w-100"
            >
              <div class="d-flex">
                <div>Go</div>
                <div class="ms-auto">
                  <font-awesome-icon
                    icon="fa-solid fa-arrow-up"
                    transform="rotate-45"
                  />
                </div>
              </div>
            </b-button>
          </b-col>
          <b-col cols="8">
            <b-button
              v-show="readyForProcessing"
              type="button"
              variant="primary"
              class="ms-1 text-start w-100 text-secondary"
              @click="startAddressProcessing"
            >
              <div class="d-flex">
                <div
                  v-if="
                    route.name === 'FireAddressLookup' ||
                    route.name === 'WindAddressLookup'
                  "
                >
                  <div v-if="!isMobile">Simulate with this address</div>
                  <div v-else>Use this address</div>
                </div>
                <div v-else-if="route.name === 'WhatsMyRiskAddressLookup'">
                  <div>What's my risk?</div>
                </div>
                <div class="ms-auto">
                  <font-awesome-icon
                    icon="fa-solid fa-arrow-up"
                    transform="rotate-45"
                  />
                </div>
              </div>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col md="6" class="mt-3 mt-md-0">
      <div v-show="readyForProcessing">
        <div class="text-center text-md-end">
          {{ confirmedAddress }}
        </div>
        <div v-show="showStreetView" class="d-flex">
          <div id="street-view" class="street-view ms-auto"></div>
        </div>
      </div>
      <div v-show="!readyForProcessing" class="text-center">
        <b-img :src="homeImage" fluid class="p-2"></b-img>
      </div>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
.street-view {
  height: 300px;
  width: 500px;
}

.street-view iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
