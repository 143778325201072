import { defineStore } from "pinia";
import api from "@/services/api";

export const useChatStore = defineStore("chat", () => {
  // State

  // Actions

  /**
   * Starts a new chat session and returns the session ID.
   *
   * @returns {string} The session ID.
   */
  const startChatSession = async (address: string, zip_code: string) => {
    try {
      const response = await api.post("/risk-chat/start", {
        address,
        zip_code,
      });

      return response.data.session_id;
    } catch (error) {
      console.error("Error starting chat session:", error);
      alert("Error starting chat session. Please try again.");
    }
  };

  /**
   * Sends a message to the chat session that corresponds to the given session ID.
   *
   * @param {string} sessionID - The session to send the message to.
   * @param {string} message - The message to be sent.
   * @param {string} prompt - Guidance for how the AI should respond.
   * @returns {string} The AI response to the message.
   */
  const sendMessage = async (
    sessionId: string,
    message: string,
    prompt: string,
  ) => {
    try {
      const response = await api.post("/risk-chat/message", {
        session_id: sessionId,
        message,
        prompt,
      });

      return response.data.response;
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error sending message. Please try again.");
    }
  };

  /**
   * End the chat session that corresponds to the given session ID.
   *
   * @returns {string} The session ID.
   */
  const endChatSession = async (sessionId: string) => {
    try {
      await api.post(
        "/risk-chat/end",
        {},
        {
          params: {
            session_id: sessionId,
          },
        },
      );
    } catch (error) {
      // Do nothing. Ending a chat session is not currently
      // necessary. So, no need to catch an error.
    }
  };

  return {
    startChatSession,
    sendMessage,
    endChatSession,
  };
});
