import { Ref, ref } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useUserStore = defineStore("userStore", () => {
  // State
  const streetAddress: Ref<string> = useLocalStorage("streetAddress", "");
  const zipcode: Ref<string> = useLocalStorage("zipcode", "");
  const confirmedAddress: Ref<string> = ref("");
  const chatSessionId: Ref<string> = useLocalStorage("chatSessionId", "");

  // Actions

  /**
   * Sets the street address value.
   *
   * @param {string} newStreetAddress - The new street address to be set.
   */
  function setStreetAddress(newStreetAddress: string) {
    streetAddress.value = newStreetAddress;
  }

  /**
   * Sets the zipcode value.
   *
   * @param {string} newZipcode - The new zipcode to be set.
   */
  function setZipcode(newZipcode: string) {
    zipcode.value = newZipcode;
  }

  /**
   * Sets the confirmed address value which typically comes back from Google Street View.
   *
   * @param {string} newConfirmedAddress - The new confirmed address to be set.
   */
  function setConfirmedAddress(newConfirmedAddress: string) {
    confirmedAddress.value = newConfirmedAddress;
  }

  /**
   * Sets the chat session ID value.
   *
   * @param {string} newChatSessionId - The new chat session ID to be set.
   */
  function setChatSessionId(newChatSessionId: string) {
    chatSessionId.value = newChatSessionId;
  }

  return {
    streetAddress,
    zipcode,
    confirmedAddress,
    chatSessionId,
    setStreetAddress,
    setZipcode,
    setConfirmedAddress,
    setChatSessionId,
  };
});
