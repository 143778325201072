export enum DamageType {
  Fire = "fire",
  Hurricane = "hurricane",
  Tornado = "tornado",
}

export enum DamageCategory {
  Fire = "fire",
  Wind = "wind",
}

export enum ChatMessageType {
  System = "system",
  User = "user",
  Watson = "watson",
}

export type ChatMessage = {
  message_text: string;
  message_type: ChatMessageType;
  timestamp: string;
  visible: boolean;
};
