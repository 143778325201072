<script setup lang="ts">
import { onBeforeUnmount, onMounted, Ref, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import api from "@/services/api";
import { ProcessingRunIdImageNumberResponse } from "../types/processingRunTypes";
import { MediaType, MediaItem } from "@/types/mediaTypes";
import { useUserStore } from "@/stores/user.ts";
import { useProcessStore } from "@/stores/process";
import { useChatStore } from "@/stores/chat";

const route = useRoute();

const userStore = useUserStore();
const { streetAddress: savedStreetAddress, zipcode: savedZipcode } =
  storeToRefs(userStore);

const processStore = useProcessStore();
const { damageType, submittedImage, mediaItems, mediaGenerationInProgress } =
  storeToRefs(processStore);
const { setMediaItems, setMediaGenerationInProgress } = processStore;

const chatStore = useChatStore();
const { startChatSession, sendMessage, endChatSession } = chatStore;

let NUMBER_OF_SIMULATOR_IMAGES: number;
let NUMBER_OF_TOTAL_MEDIA_ITEMS: number;
const pollingImagesIntervalTime: number = 2000;
let pollingTimer: ReturnType<typeof setTimeout> | null = null;
const currentPollingImageNumber = ref(1);

const overallRiskNumber: Ref<string> = ref("");
const riskNumberReady: Ref<boolean> = ref(false);

const showOverlay = computed(() => {
  return route.name === "WhatsMyRiskResults" && !riskNumberReady.value;
});

/**
 * Initializes the media items collection to have placeholders.
 *
 * @param numberOfItems
 */
const initializeMediaItems = (numberOfItems: number) => {
  const initializedMediaItems = Array(0);

  for (let i = 0; i < numberOfItems; i++) {
    initializedMediaItems.push(null);
  }

  // Set the first image to the original street view image.
  initializedMediaItems[0] = {
    src: submittedImage.value,
    type: MediaType.ImageJPEG,
  } as MediaItem;

  setMediaItems(initializedMediaItems);
};

/**
 * Starts a new chat session and gets the overall risk number.
 */
const getOverallRiskNumber = async () => {
  const sessionId = await startChatSession(
    savedStreetAddress.value,
    savedZipcode.value,
  );

  overallRiskNumber.value = await sendMessage(
    sessionId,
    "On a scale of 1-5, what is my overall risk? Just give me a number, no explanation.",
    "",
  );

  await endChatSession(sessionId);
};

/**
 * Query the JPEG image polling API endpoint.
 *
 * @param runId the run ID
 * @param imageNumber the JPEG image number to retrieve
 * @returns Promise<ProcessingRunIdImageNumberResponse | null>
 */
const queryImageJpegPollingEndpoint = async (
  runId: number,
  imageNumber: number,
): Promise<ProcessingRunIdImageNumberResponse | null> => {
  const response = await api.get<ProcessingRunIdImageNumberResponse>(
    `/processing-run/${runId}/image/${imageNumber}`,
  );
  const data = response.data;
  if (data.progression_image != null) {
    return data;
  }
  return null;
};

/**
 * Poll for all the JPEG images associated with the given run ID. Polling is
 * done image-by-image in intervals of 3 seconds.
 *
 * @param runId the run ID
 */
const pollImagesJpeg = async (runId: number) => {
  if (
    mediaGenerationInProgress.value &&
    currentPollingImageNumber.value <= NUMBER_OF_SIMULATOR_IMAGES
  ) {
    try {
      const response = await queryImageJpegPollingEndpoint(
        runId,
        currentPollingImageNumber.value,
      );
      if (response !== null) {
        console.log(
          `JPEG Image polling got a response (with runId: ${runId} and imageNumber: ${currentPollingImageNumber.value})`,
        );

        // Shift image insertion by one since the first slide is the
        // submitted image.
        mediaItems.value[currentPollingImageNumber.value] = {
          src: `data:image/jpeg;base64,${response.progression_image}`,
          type: MediaType.ImageJPEG,
        };

        currentPollingImageNumber.value++;
      } else {
        console.log(
          `JPEG Image polling response for runId: ${runId}, imageNumber: ${currentPollingImageNumber.value} was null, continuing to poll every ${pollingImagesIntervalTime / 1000} seconds...`,
        );
      }

      if (pollingTimer) {
        clearTimeout(pollingTimer);
        pollingTimer = null;
      }

      pollingTimer = setTimeout(() => {
        pollImagesJpeg(runId);
      }, pollingImagesIntervalTime);
    } catch (err: any) {
      console.log(`Error while polling for JPEG images: ${err.message}`);
    }
  } else {
    // Polling is complete.
    setMediaGenerationInProgress(false);
    return;
  }
};

/**
 * Begin polling for the all the JPEG images associated with the given
 * run ID.
 *
 * @param runId the run ID
 */
async function startPolling(runId: number) {
  setMediaGenerationInProgress(true);
  pollImagesJpeg(runId);
}

onMounted(async () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  console.log(`Got runId: ${route.params.uuid}`);

  if (
    route.name === "FireSimulatorResults" ||
    route.name === "WindSimulatorResults"
  ) {
    NUMBER_OF_SIMULATOR_IMAGES = 5;
    NUMBER_OF_TOTAL_MEDIA_ITEMS = 6;

    initializeMediaItems(NUMBER_OF_TOTAL_MEDIA_ITEMS);

    if (typeof route.params.uuid == "string") {
      const uuidNum = Number(route.params.uuid);
      startPolling(uuidNum);
    }
  }

  if (route.name === "WhatsMyRiskResults") {
    riskNumberReady.value = false;

    NUMBER_OF_SIMULATOR_IMAGES = 0;
    NUMBER_OF_TOTAL_MEDIA_ITEMS = 1;
    initializeMediaItems(NUMBER_OF_TOTAL_MEDIA_ITEMS);

    await getOverallRiskNumber();
    riskNumberReady.value = true;
  }
});

onBeforeUnmount(() => {
  if (pollingTimer) {
    clearTimeout(pollingTimer);
    pollingTimer = null;
  }
});
</script>

<template>
  <b-overlay :show="showOverlay" variant="white" opacity="1">
    <template #overlay>
      <div class="d-flex flex-column justify-content-center">
        <div class="text-center fs-3">Evaluating your risk...</div>
        <div class="text-center">
          <b-spinner class="mt-4" variant="primary"></b-spinner>
        </div>
      </div>
    </template>
    <b-row class="my-2 my-md-3">
      <b-col>
        <div class="fs-4 fw-bold text-center">
          <div
            v-if="
              route.name === 'FireSimulatorResults' ||
              route.name === 'WindSimulatorResults'
            "
          >
            This is what your home could look like if damaged by
            {{ damageType }}.
          </div>
          <div v-if="route.name === 'WhatsMyRiskResults'">
            <div v-show="riskNumberReady">
              We ranked your overall risk at {{ overallRiskNumber }} out of 5.
              Here are some tools to mitigate it.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-end">
      <b-col md="6">
        <MediaContainer></MediaContainer>
      </b-col>
      <b-col md="6" class="mt-3 mt-md-0">
        <WatsonChatbot></WatsonChatbot>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

::v-deep(.carousel) {
  max-width: 400px;
  margin: 0 auto;

  .carousel-control-prev {
    opacity: 1;

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.354a.5.5 0 0 1 0 .707L5.207 8l6.147 5.939a.5.5 0 1 1-.708.708l-6.5-6.5a.5.5 0 0 1 0-.708l6.5-6.5a.5.5 0 0 1 .707 0z'/%3E%3C/svg%3E");
      background-color: $brand-primary;
      opacity: 1;
      margin-left: -3rem;
      padding: 1rem 1rem;
      border: $brand-primary solid 5px;
    }
  }

  .carousel-control-next {
    opacity: 1;

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 14.646a.5.5 0 0 1 0-.707L10.793 8 4.646 2.061a.5.5 0 1 1 .708-.708l6.5 6.5a.5.5 0 0 1 0 .708l-6.5 6.5a.5.5 0 0 1-.708 0z'/%3E%3C/svg%3E");
      background-color: $brand-primary;
      opacity: 1;
      margin-right: -3rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding: 1rem 1rem;
      border: $brand-primary solid 5px;
    }
  }
}
</style>
