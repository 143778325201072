<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import { useAppStore } from "@/stores/app";

import headerImageMagicWindow from "@/assets/images/magic_window_logo.svg";
import windIcon from "@/assets/images/wind-icon.svg";
import rainIcon from "@/assets/images/rain-icon.svg";
import temperatureIcon from "@/assets/images/temperature-icon.svg";
import fireIcon from "@/assets/images/fire-icon.svg";

const appStore = useAppStore();
const { iframeParentUrl, isMobile, isTablet } = storeToRefs(appStore);

const API_URL = import.meta.env.VITE_API_URL as string;

// Timeline range
const startYear = 1950;
const endYear = 2050;

// Get current year from system time
let currentYear = new Date().getFullYear();
// Clamp currentYear just in case
if (currentYear < startYear) currentYear = startYear;
if (currentYear > endYear) currentYear = endYear;

// Data options
const allDataOptions = ["WIND", "RAIN", "TEMP", "FIRE"] as const;
type DataOption = (typeof allDataOptions)[number];

const selectedData = ref<DataOption>("WIND");

type ViewMode = "Climate" | "Weather";
const viewMode = ref<ViewMode>("Weather"); // Weather selected by default

// Start at the current year
const selectedYear = ref<number>(currentYear);

// Filter data options: if Weather, show all including FIRE
// If Climate, exclude FIRE
const visibleDataOptions = computed(() => {
  if (viewMode.value === "Weather") {
    return allDataOptions;
  } else {
    return allDataOptions.filter((o) => o !== "FIRE");
  }
});

/**
 * Separate data endpoints for Weather and Climate.
 * Weather endpoints remain the same:
 *  - WIND -> windSpeed
 *  - RAIN -> precipAndSnow1hrCumulativeFcst
 *  - TEMP -> temp
 *  - FIRE -> fire
 *
 * Climate endpoints are updated as follows:
 *  - WIND -> surface_wind
 *  - RAIN -> precipitation
 *  - TEMP -> temperature
 *  (FIRE is excluded in climate mode)
 */
const dataEndpointsWeather: Record<DataOption, string> = {
  WIND: "windSpeed",
  RAIN: "precipAndSnow1hrCumulativeFcst",
  TEMP: "temp",
  FIRE: "fire",
};

const dataEndpointsClimate: Record<Exclude<DataOption, "FIRE">, string> = {
  WIND: "surface_wind",
  RAIN: "precipitation",
  TEMP: "temperature",
};

// Compute the final iframe source based on the selected view mode
const iframeSrc = computed(() => {
  // If climate mode is selected, pick from the climate endpoints
  if (viewMode.value === "Climate") {
    const endpoint =
      dataEndpointsClimate[selectedData.value as Exclude<DataOption, "FIRE">];
    return `${API_URL}/map/climate/${endpoint}/${selectedYear.value}`;
  }
  // Otherwise, use the weather endpoints
  else {
    const endpoint = dataEndpointsWeather[selectedData.value];
    return `${API_URL}/map/${endpoint}`;
  }
});

/**
 * Handle when the What's My Risk? button is clicked.
 */
const onWhatsMyRiskClick = () => {
  // This sends a message to the parent window that the What's My Risk? feature
  // should be displayed in the second dialog. The execution for this request
  // is handled by the parent window.
  window.parent.postMessage(
    { dialogName: "dialog2", message: "show risk dialog" },
    iframeParentUrl.value,
  );
};

// Generate ticks every 5 years
const yearMarks = computed(() => {
  const marks = [];
  for (let y = startYear; y <= endYear; y += 5) {
    marks.push(y);
  }
  return marks;
});

// Gets the button icon to display for the given data option.
function getButtonIcon(option: string): string {
  switch (option) {
    case "WIND":
      return windIcon;
    case "RAIN":
      return rainIcon;
    case "TEMP":
      return temperatureIcon;
    case "FIRE":
      return fireIcon;
    default:
      return "";
  }
}

// Convert a year to a percent along the track
function yearToPercent(year: number) {
  return (year - startYear) / (endYear - startYear);
}

function tickPosition(year: number) {
  return `${yearToPercent(year) * 100}%`;
}

const handlePosition = computed(() => {
  return `${yearToPercent(selectedYear.value) * 100}%`;
});

// "PRESENT" label should be above the current year's handle
const presentLabelPosition = computed(() => {
  return `${yearToPercent(currentYear) * 100}%`;
});

// Allow clicking on the timeline to set the year
const onTimelineClick = (e: MouseEvent) => {
  const container = e.currentTarget as HTMLElement;
  const rect = container.getBoundingClientRect();
  const clickX = e.clientX - rect.left;
  const width = rect.width;
  const percent = clickX / width;
  const year = Math.round(startYear + percent * (endYear - startYear));
  selectedYear.value = Math.min(Math.max(year, startYear), endYear);
};
</script>

<template>
  <DefaultLayout
    :headerImage="headerImageMagicWindow"
    headerTitle="Magic Window"
  >
    <!-- HEADER -->
    <template #header>
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 text-end text-lg-center">
          <b-form-radio-group
            v-model="selectedData"
            buttons
            button-variant="info"
            :size="isMobile || isTablet ? 'sm' : 'md'"
            class="d-flex-inline gap-1 gap-md-2"
          >
            <template v-for="option in visibleDataOptions" :key="option">
              <b-form-radio :value="option">
                <div class="d-flex align-items-center">
                  <div v-if="!isMobile || isTablet" class="me-3">
                    {{ option }}
                  </div>
                  <b-img
                    height="18"
                    width="18"
                    :src="getButtonIcon(option)"
                  ></b-img>
                </div>
              </b-form-radio>
            </template>
          </b-form-radio-group>
        </div>
        <div v-if="!isMobile && !isTablet" class="d-flex">
          <div class="mt-2 mt-lg-0">
            <b-form-radio-group
              v-model="viewMode"
              buttons
              button-variant="info"
            >
              <b-form-radio value="Climate"> Climate </b-form-radio>
              <b-form-radio value="Weather"> Weather </b-form-radio>
            </b-form-radio-group>
          </div>
          <div class="mt-4 mt-lg-0">
            <b-button
              variant="primary"
              class="text-dark ms-2"
              @click="onWhatsMyRiskClick"
            >
              <div class="d-flex">
                <div class="me-2">What's My Risk?</div>
                <div class="ms-auto">
                  <font-awesome-icon
                    icon="fa-solid fa-arrow-up"
                    transform="rotate-45"
                  />
                </div>
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </template>

    <!-- MAIN CONTENT AREA -->
    <div v-if="isMobile || isTablet" class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-center my-2">
        <div class="">
          <b-form-radio-group
            v-model="viewMode"
            buttons
            button-variant="light"
            size="sm"
          >
            <b-form-radio value="Climate"> Climate </b-form-radio>
            <b-form-radio value="Weather"> Weather </b-form-radio>
          </b-form-radio-group>
        </div>
        <div class="">
          <b-button
            variant="primary"
            class="text-dark ms-2"
            size="sm"
            @click="onWhatsMyRiskClick"
          >
            <div class="me-2">What's My Risk?</div>
          </b-button>
        </div>
      </div>
    </div>
    <div class="main-content">
      <iframe :src="iframeSrc" class="map-iframe"></iframe>
    </div>

    <!-- FOOTER/TIMELINE (VISIBLE ONLY IF CLIMATE SELECTED) -->
    <footer v-if="viewMode === 'Climate'" class="footer">
      <!-- PAST and FUTURE at the ends -->
      <div class="timeline-labels">
        <span>PAST</span>
        <span>FUTURE</span>
      </div>
      <div class="timeline-container" @click="onTimelineClick">
        <!-- The track with ticks and year labels -->
        <div class="timeline-track">
          <div class="ticks">
            <div
              v-for="year in yearMarks"
              :key="year"
              class="tick-container"
              :style="{ left: tickPosition(year) }"
            >
              <div class="tick"></div>
              <div class="tick-year">{{ year }}</div>
            </div>
          </div>

          <!-- The current year handle label -->
          <div class="current-year-label" :style="{ left: handlePosition }">
            {{ selectedYear }}
          </div>

          <!-- PRESENT label above the current year's handle -->
          <div class="present-label" :style="{ left: presentLabelPosition }">
            PRESENT
          </div>
        </div>

        <!-- The range input overlay -->
        <input
          type="range"
          min="1950"
          max="2050"
          step="1"
          v-model.number="selectedYear"
          class="timeline-range"
        />
      </div>
    </footer>
  </DefaultLayout>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

/* MAIN CONTENT */
.main-content {
  flex: 1;
  position: relative;
  overflow: clip;
  height: 80vh;
}

.map-iframe {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

/* FOOTER / TIMELINE */
.footer {
  position: relative;
  background: rgba(43, 43, 44, 0.8);
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.timeline-labels {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.timeline-container {
  position: relative;
  height: 60px;
  cursor: pointer;
}

/* The track behind the ticks */
.timeline-track {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  height: 8px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  overflow: visible;
}

/* Ticks */
.ticks {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tick-container {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  text-align: center;
}

.tick {
  width: 2px;
  height: 10px;
  background: #fff;
  margin: 0 auto;
}

.tick-year {
  color: #fff;
  font-size: 0.7rem;
  margin-top: 2px;
}

/* Current year label */
.current-year-label {
  position: absolute;
  bottom: 30px;
  transform: translateX(-50%);
  background: #fff;
  color: #000;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* PRESENT label above current year */
.present-label {
  position: absolute;
  bottom: 50px;
  transform: translateX(-50%);
  background: #fff;
  color: #000;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* RANGE INPUT STYLING */
.timeline-range {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  opacity: 0;
}

/* Slider thumb for WebKit browsers */
.timeline-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #000;
  margin-top: -3px;
  cursor: pointer;
}

/* Slider thumb for Firefox */
.timeline-range::-moz-range-thumb {
  background: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #000;
  cursor: pointer;
}

/* Slider thumb for IE/Edge */
.timeline-range::-ms-thumb {
  background: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #000;
  cursor: pointer;
}

.timeline-range::-webkit-slider-runnable-track {
  background: transparent;
}

.timeline-range::-moz-range-track {
  background: transparent;
}

.timeline-range::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}
</style>
