<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, Ref } from "vue";
import { storeToRefs } from "pinia";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { uniqBy } from "lodash";
import { ChatMessage, ChatMessageType, DamageType } from "@/types/processTypes";
import api from "@/services/api";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/user.ts";
import { MediaType, MediaItem } from "@/types/mediaTypes";
import { useProcessStore } from "@/stores/process";
import { useChatStore } from "@/stores/chat";

const route = useRoute();

const processStore = useProcessStore();
const { damageType, mediaItems } = storeToRefs(processStore);
const {
  setMediaItems,
  setMediaItemIndex,
  setMediaGenerationInProgress,
  setEnableAutoSlide,
} = processStore;

const userStore = useUserStore();
const {
  streetAddress: savedStreetAddress,
  zipcode: savedZipcode,
  chatSessionId: savedChatSessionId,
} = storeToRefs(userStore);
const { setChatSessionId } = userStore;

const chatStore = useChatStore();
const { startChatSession, sendMessage } = chatStore;

let readyToSubmitMessages: Ref<boolean> = ref(false);
let waitingForChatResponse: Ref<boolean> = ref(false);
let userMessageToSubmit: Ref<string> = ref("");
let chatHistory: Ref<Array<ChatMessage>> = ref([]);
let chatBox: HTMLElement | null = null;
let observerChatBox: MutationObserver | null = null;
let videoMediaItems: Ref<Array<MediaItem>> = ref([]);

/**
 * Starts a new chat session for the chat bot if none exists.
 */
const startChatSessionForChatBot = async () => {
  if (!savedChatSessionId.value) {
    const sessionId = await startChatSession(
      savedStreetAddress.value,
      savedZipcode.value,
    );
    setChatSessionId(sessionId);
    sendMessageInChatBot(
      `You are a climate and risk mitigation model. You do not provide
        any other information. If a user sends a message unrelated to
        climate or risk mitigation, respond with the following: I'm sorry. 
        I can only respond to inquiries related to climate and risk
        mitigation. Please try again.`,
      "",
    );
    sendMessageInChatBot(
      `Explain without showing specific statistics, scores, metrics or any 
      other numbers. Use qualitiative expressions. Using the rating when
      possible.`,
      "",
    );
  }
};

/**
 * Retrieves the chat message history for the current session.
 */
const getMessageHistory = async () => {
  try {
    const response = await api.get("/risk-chat/history", {
      params: {
        session_id: savedChatSessionId.value,
      },
    });
    chatHistory.value = response.data.messages;
  } catch (error) {
    console.error("Error getting chat history:", error);
    alert("Error getting chat history. Please try again.");
  }
};

/**
 * Determines if the chat history has the given user message.
 *
 * @param {string} userMessage - The user message to look for
 */
const chatHistoryHasUserMessage = (userMessage: string) => {
  return chatHistory.value.some(
    (message: ChatMessage) =>
      message.message_type === ChatMessageType.User &&
      message.message_text === userMessage,
  );
};

/**
 * Sends a message to the chat session and updates the chat history
 * with the user's query and the server's response.
 *
 * @param {string} prompt - The prompt to send to the AI model for guidance.
 *   It is not displayed in the chatbox.
 * @param {string} message - The message to be sent.
 */
const sendMessageInChatBot = async (prompt: string, message: string) => {
  try {
    if (savedChatSessionId.value) {
      // Set overall guidance for the AI responses.
      if (prompt && !message) {
        await sendMessage(savedChatSessionId.value, "", prompt);
        // Send a user message with or without message-specific AI guidance.
      } else if (message) {
        chatHistory.value.push({
          message_text: message,
          message_type: ChatMessageType.User,
          timestamp: new Date().toISOString(),
          visible: true,
        });

        userMessageToSubmit.value = "";
        waitingForChatResponse.value = true;

        const messageResponse = await sendMessage(
          savedChatSessionId.value,
          message,
          prompt || "",
        );

        chatHistory.value.push({
          // JIA TODO: Testing video link
          //message_text: messageResponse,
          message_text:
            chatHistory.value.length > 2
              ? messageResponse +
                "  \n - [Test Link - Earth](https://archive.org/download/Sample-Video-Mp4/IMG_6303.MP4)" +
                "  \n - [Test Link - Lions](https://archive.org/download/lion_20210527/Lion.mp4)" +
                "  \n - [Test Link - Deep Sea](https://archive.org/download/Natural_History_Wildlife/Nature%201984%20-%20The%20Face%20of%20the%20Deep.mp4)"
              : messageResponse,
          message_type: ChatMessageType.Watson,
          timestamp: new Date().toISOString(),
          visible: true,
        });

        waitingForChatResponse.value = false;
      }
    } else {
      console.error("Session ID required to send a message.");
    }
  } catch (error) {
    console.error("Error sending message:", error);
    alert("Error sending message. Please try again.");
  }
};

/**
 * Sends the first message to the chat if it hasn't been sent already.
 */
const sendFirstMessageIfNecessary = async () => {
  const fireSimFirstMessage = `How can I mitigate my ${DamageType.Fire} risk?`;
  const windTornadoSimFirstMessage = `How can I mitigate my ${DamageType.Tornado} risk?`;
  const windHurricaneSimFirstMessage = `How can I mitigate my ${DamageType.Hurricane} risk?`;
  const whatsMyRiskFirstMessage = `How can I mitigate my risk?`;

  const commonPrompt = `Provide up to three suggestions for each risk 
      under consideration. Then, ask the user if they would like to know more.`;

  if (
    route.name === "FireSimulatorResults" &&
    !chatHistoryHasUserMessage(fireSimFirstMessage)
  ) {
    sendMessageInChatBot(commonPrompt, fireSimFirstMessage);
  } else if (route.name === "WindSimulatorResults") {
    if (
      damageType.value === DamageType.Tornado &&
      !chatHistoryHasUserMessage(windTornadoSimFirstMessage)
    ) {
      sendMessageInChatBot(commonPrompt, windTornadoSimFirstMessage);
    } else if (
      damageType.value === DamageType.Hurricane &&
      !chatHistoryHasUserMessage(windHurricaneSimFirstMessage)
    ) {
      sendMessageInChatBot(commonPrompt, windHurricaneSimFirstMessage);
    }
  } else if (
    route.name === "WhatsMyRiskResults" &&
    !chatHistoryHasUserMessage(whatsMyRiskFirstMessage)
  ) {
    sendMessageInChatBot(commonPrompt, whatsMyRiskFirstMessage);
  }
};

/**
 * Sets up a DOM mutation observer for the following behaviors:
 * - Automatic scroll for the chat box. Ensures the chat box always scrolls
 *   so that the latest user message will be in view.
 * - Video link extraction and setting of media items in the MediaContainer.
 */
const setupMutationObserver = () => {
  if (chatBox) {
    observerChatBox = new MutationObserver(() => {
      // Automatic scroll.
      const userMessages = chatBox?.querySelectorAll(".chat-box-user-message");
      if (userMessages && userMessages.length > 0) {
        const lastUserMessage = userMessages[userMessages?.length - 1];
        if (lastUserMessage) {
          lastUserMessage.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }

      // Video link extraction.
      videoMediaItems.value = Array.from(chatBox?.querySelectorAll("a") || [])
        .filter((a) => {
          const url = new URL(a.href);
          return url.pathname.toLowerCase().endsWith(".mp4");
        })
        .map((a) => {
          return {
            src: a.href,
            type: MediaType.VideoMP4,
          } as MediaItem;
        });
      videoMediaItems.value = uniqBy(videoMediaItems.value, "src");

      // Keeps original slides in carousel
      // let newMediaItems: Array<MediaItem> = [...mediaItems.value];
      // const srcValues = compact(newMediaItems.map(n => { return n?.src }));

      // videoMediaItems.map(videoMediaItem => {
      //   if (!srcValues.includes(videoMediaItem.src)) {
      //     newMediaItems.push(videoMediaItem);
      //   }
      // })

      // if (newMediaItems.length > mediaItems.value.length) {
      //   setMediaGenerationInProgress(false);
      //   setEnableAutoSlide(false);
      //   setMediaItems([...newMediaItems]);
      // }
    });
    observerChatBox.observe(chatBox as Node, { childList: true });
  }
};

/**
 * Tears down the chat box auto-scroll behavior by disconnecting the
 * MutationObserver.
 */
const tearDownMutationObserver = () => {
  if (observerChatBox) {
    observerChatBox.disconnect();
    observerChatBox = null;
  }
};

/**
 * Handles any clicks in the chat box. This is primarily so that a link
 * can be played in the MediaContainer if it is an MP4 file.
 */
const handleChatBoxClicks = (event: Event) => {
  const target = event.target as HTMLElement;

  // Replaces the slides in carousel with the video slides.
  if (videoMediaItems.value.length > 0) {
    setMediaGenerationInProgress(false);
    setEnableAutoSlide(false);
    setMediaItems([...videoMediaItems.value]);
  }

  // If the clicked element is an <a> tag and the href is an MP4 file,
  // set it to display it in the MediaContainer.
  if (target.tagName === "A") {
    event.preventDefault();
    const href = (target as HTMLAnchorElement).href;
    const mediaItemIndex = mediaItems.value.findIndex(
      (m: MediaItem | null) => m?.src === href,
    );
    if (mediaItemIndex >= 0) {
      setMediaItemIndex(mediaItemIndex);
    }
  }
};

/**
 * Converts a markdown message to HTML.
 *
 * @param {string} message - The markdown message to be converted.
 * @returns {string} The converted HTML string.
 */
const markdownToHtml = (message: string): string => {
  return marked(message) as string;
};

onMounted(async () => {
  chatBox = document.getElementById("chat-box");
  chatBox?.addEventListener("click", handleChatBoxClicks);

  setupMutationObserver();
  await startChatSessionForChatBot();
  await getMessageHistory();
  sendFirstMessageIfNecessary();

  readyToSubmitMessages.value = true;
});

onBeforeUnmount(() => {
  tearDownMutationObserver();

  if (chatBox) {
    chatBox.removeEventListener("click", handleChatBoxClicks);
  }
});
</script>

<template>
  <b-row>
    <b-col>
      <div id="chat-box" class="overflow-auto mb-2">
        <div v-for="message in chatHistory" :key="message.timestamp">
          <div
            v-if="message.message_type === ChatMessageType.User"
            class="text-end chat-box-user-message"
          >
            <div
              class="d-inline-block p-2 m-2 bg-secondary text-white rounded-top rounded-start"
            >
              {{ message.message_text }}
            </div>
          </div>
          <div
            v-if="message.message_type === ChatMessageType.Watson"
            class="text-start"
          >
            <div
              v-html="DOMPurify.sanitize(markdownToHtml(message.message_text))"
            ></div>
          </div>
        </div>
        <div v-if="waitingForChatResponse" class="text-end me-3">
          <b-spinner></b-spinner>
        </div>
      </div>
    </b-col>
  </b-row>
  <b-row class="mb-3 mb-md-0">
    <b-col>
      <b-form @submit.prevent="sendMessageInChatBot('', userMessageToSubmit)">
        <b-row>
          <b-col>
            <b-form-group class="mb-0">
              <b-form-input
                v-model="userMessageToSubmit"
                placeholder="Ask your next question..."
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="auto" class="d-flex align-items-center">
            <b-button
              type="submit"
              :disabled="!readyToSubmitMessages || waitingForChatResponse"
            >
              <font-awesome-icon icon="fa-regular fa-paper-plane" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
</template>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

#chat-box {
  max-height: 360px;
  height: 360px;

  a {
    color: blue;
  }
}
</style>
