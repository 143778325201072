<script setup>
// TODO: Make this <script setup lang="ts">
// and define props like as follows.
//
// interface Props {
//  mediaGenerationInProgress: boolean;
//}
//const { mediaGenerationInProgress = false } =
//  defineProps<Props>();

import { onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useAppStore } from "@/stores/app";

defineProps({
  headerImage: {
    type: String,
    default: "",
  },
  headerTitle: {
    type: String,
    default: "Header Title",
  },
});

const appStore = useAppStore();
const { iframeParentUrl, isMobile, isTablet } = storeToRefs(appStore);

const route = useRoute();

/**
 * Handle when the close button is clicked.
 */
function onCloseButtonClick() {
  // This sends a message to the parent window that the app should be hidden.
  // How this happens is determined by the parent. Currently, the app is being
  // host in an iframe with a dialog on the parent window. So, when the parent
  // window receives this message it will close the dialog.
  window.parent.postMessage(
    { dialogName: route.query.dialogName, message: "hide app" },
    iframeParentUrl.value,
  );
}

onMounted(() => {});

onBeforeUnmount(() => {});
</script>

<template>
  <div class="layout">
    <b-container fluid>
      <b-row class="g-0 mt-3">
        <b-col>
          <!-- Header -->
          <header
            class="layout-header d-flex align-items-center p-1 rounded-3 text-light bg-secondary"
          >
            <b-img
              v-if="!isMobile && headerImage"
              class="m-2"
              :src="headerImage"
            ></b-img>
            <div class="m-2 text-uppercase">{{ headerTitle }}</div>
            <div class="m-2 flex-grow-1">
              <slot name="header"> </slot>
            </div>
            <b-button
              id="close-button"
              class="ms-auto ms-auto me-2 my-2"
              @click="onCloseButtonClick"
              :size="isMobile || isTablet ? 'sm' : 'md'"
              ><font-awesome-icon icon="fa-solid fa-xmark" size="sm"
            /></b-button>
          </header>
        </b-col>
      </b-row>
      <b-row class="g-0">
        <b-col>
          <!-- Main -->
          <main class="layout-content">
            <slot></slot>
          </main>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.layout {
  .layout-header {
    #close-button {
      background-color: rgba(229, 229, 229, 0.1);
    }
  }
}
</style>
