<script setup lang="ts">
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { useAppStore } from "@/stores/app";
import { useUserStore } from "@/stores/user";
import { useProcessStore } from "@/stores/process";

// JIA TODO: This needs to be the primary color and rounded like the
// other logos. Or just use the one Daisey has in the design. It's the
// same one used for wildfire.
//import headerImageWhatsMyRisk from "@/assets/images/whats_my_risk_logo.png";
import headerImageWhatsMyRisk from "@/assets/images/fire_simulator_logo.svg";
import mainBannerWhatsMyRisk from "@/assets/images/whats_my_risk_banner.png";

const router = useRouter();
const route = useRoute();

const appStore = useAppStore();
const { isMobile } = storeToRefs(appStore);

const userStore = useUserStore();
const { setStreetAddress, setZipcode, setChatSessionId } = userStore;

const processStore = useProcessStore();
const { setReadyForProcessing } = processStore;

const headerTitle: string = "What's My Risk?";
const headerImage: string = headerImageWhatsMyRisk;
const mainBanner: string = mainBannerWhatsMyRisk;

/**
 * Starts the risk evaluation over and clears any appropriate data.
 */
function startOver() {
  setStreetAddress("");
  setZipcode("");
  setChatSessionId("");
  setReadyForProcessing(false);

  if (route.name === "WhatsMyRiskResults") {
    router.push({
      name: "WhatsMyRiskAddressLookup",
      query: {
        dialogName: route.query?.dialogName,
      },
    });
  }
}

onMounted(() => {});
</script>

<template>
  <DefaultLayout :headerImage="headerImage" :headerTitle="headerTitle">
    <!-- Header -->
    <template #header>
      <div class="d-flex align-items-center">
        <div class="ms-auto">
          <b-button
            variant="primary"
            class="text-dark ms-auto"
            :size="isMobile ? 'sm' : 'md'"
            @click="startOver"
          >
            <div class="d-flex">
              <div v-if="!isMobile" class="me-md-2">Start Over</div>
              <div>
                <font-awesome-icon
                  icon="fa-solid fa-rotate-right"
                  transform="flip-h"
                />
              </div>
            </div>
          </b-button>
        </div>
      </div>
    </template>

    <!-- Main Content -->
    <div class="main">
      <b-row v-if="route.name === 'WhatsMyRiskAddressLookup'" class="mt-2">
        <b-col>
          <b-img :src="mainBanner" class="main-image-banner"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </div>
  </DefaultLayout>
</template>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.main {
  &-image-banner {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
}
</style>
