<script setup lang="ts">
import { computed, onMounted, ComputedRef } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { DamageCategory, DamageType } from "@/types/processTypes";

import { useAppStore } from "@/stores/app";
import { useUserStore } from "@/stores/user";
import { useProcessStore } from "@/stores/process";

import headerImageFire from "@/assets/images/fire_simulator_logo.svg";
import headerImageWind from "@/assets/images/wind_simulator_logo.svg";
import tornadoIcon from "@/assets/images/tornado-icon.svg";
import hurricaneIcon from "@/assets/images/hurricane-icon.svg";
import mainBannerFire from "@/assets/images/fire_simulator_banner.png";
import mainBannerWindTornado from "@/assets/images/wind_tornado_simulator_banner.png";
import mainBannerWindHurricane from "@/assets/images/wind_hurricane_simulator_banner.png";

const router = useRouter();
const route = useRoute();

const appStore = useAppStore();
const { isMobile } = storeToRefs(appStore);

const userStore = useUserStore();
const { setStreetAddress, setZipcode, setChatSessionId } = userStore;

const processStore = useProcessStore();
const { damageType, damageCategory } = storeToRefs(processStore);
const { setReadyForProcessing } = processStore;

const headerTitle: ComputedRef<string | null> = computed(() => {
  let headerTitle = null;
  if (damageCategory.value === DamageCategory.Fire) {
    headerTitle = "Wildfire Simulator";
  } else if (damageCategory.value === DamageCategory.Wind) {
    headerTitle = "Wind Simulator";
  }
  return headerTitle;
});

const headerImage: ComputedRef<string | null> = computed(() => {
  let headerImage = null;
  if (damageCategory.value === DamageCategory.Fire) {
    headerImage = headerImageFire;
  } else if (damageCategory.value === DamageCategory.Wind) {
    headerImage = headerImageWind;
  }
  return headerImage;
});

const mainBanner: ComputedRef<string | null> = computed(() => {
  let mainBanner = null;
  if (damageCategory.value === DamageCategory.Fire) {
    mainBanner = mainBannerFire;
  } else if (damageCategory.value === DamageCategory.Wind) {
    if (damageType.value === DamageType.Hurricane) {
      mainBanner = mainBannerWindHurricane;
    } else if (damageType.value === DamageType.Tornado) {
      mainBanner = mainBannerWindTornado;
    }
  }
  return mainBanner;
});

/**
 * Starts the simulator over and clears any appropriate data.
 */
function startOver() {
  if (route.name === "FireSimulatorResults") {
    setStreetAddress("");
    setZipcode("");
    setReadyForProcessing(false);
    setChatSessionId("");
    router.push({
      name: "FireAddressLookup",
      query: {
        dialogName: route.query?.dialogName,
      },
    });
  }

  if (route.name === "WindSimulatorResults") {
    // The street address and zip code are not being reset here
    // because the user may just want to change the wind simulator
    // type, not enter a new address.
    // The chat session ID is not being reset here because the
    // may just want to change the wind simulator type, not
    // clear out the chat messages.
    setReadyForProcessing(false);
    router.push({
      name: "WindAddressLookup",
      query: {
        dialogName: route.query?.dialogName,
      },
    });
  }

  if (
    route.name === "FireAddressLookup" ||
    route.name === "WindAddressLookup"
  ) {
    setStreetAddress("");
    setZipcode("");
    setReadyForProcessing(false);
    setChatSessionId("");
  }
}

onMounted(() => {
  damageType.value =
    route.matched[0].name === "FireSim"
      ? DamageType.Fire
      : route.matched[0].name === "WindSim"
        ? DamageType.Tornado
        : null;
});
</script>

<template>
  <DefaultLayout :headerImage="headerImage" :headerTitle="headerTitle">
    <!-- Header -->
    <template #header>
      <div class="d-flex align-items-center">
        <div
          v-if="route.matched[0].name === 'WindSim'"
          class="flex-grow-1 text-center"
        >
          <b-form-group>
            <b-form-radio-group
              v-model="damageType"
              buttons
              button-variant="info"
              :size="isMobile ? 'sm' : 'md'"
              :disabled="route.name === 'WindSimulatorResults'"
            >
              <b-form-radio :value="DamageType.Tornado">
                <div class="d-flex align-items-center">
                  <div v-if="!isMobile" class="me-3">Tornado</div>
                  <b-img :src="tornadoIcon"></b-img>
                </div>
              </b-form-radio>
              <b-form-radio :value="DamageType.Hurricane">
                <div class="d-flex align-items-center">
                  <div v-if="!isMobile" class="me-3">Hurricane</div>
                  <b-img :src="hurricaneIcon"></b-img>
                </div>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div
          :class="
            route.matched[0].name === 'WindSim' ? 'ms-2 ms-md-3' : 'ms-auto'
          "
        >
          <b-button
            variant="primary"
            class="text-dark ms-auto"
            :size="isMobile ? 'sm' : 'md'"
            @click="startOver"
          >
            <div class="d-flex">
              <div v-if="!isMobile" class="me-md-2">Start Over</div>
              <div>
                <font-awesome-icon
                  icon="fa-solid fa-rotate-right"
                  transform="flip-h"
                />
              </div>
            </div>
          </b-button>
        </div>
      </div>
    </template>

    <!-- Main Content -->
    <div class="main">
      <b-row
        v-if="
          route.name === 'FireAddressLookup' ||
          route.name === 'WindAddressLookup'
        "
        class="mt-2"
      >
        <b-col>
          <b-img :src="mainBanner" class="main-image-banner"></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </div>
  </DefaultLayout>
</template>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.main {
  &-image-banner {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
}
</style>
