import { createApp } from "vue";
import { createBootstrap } from "bootstrap-vue-next";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import axios from "axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowUp as fasArrowUp,
  faXmark as fasXmark,
  faRotateRight as fasRotateRight,
  faHurricane as fasHurricane,
  faTornado as fasTornado,
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane as farPaperPlane } from "@fortawesome/free-regular-svg-icons";

import "@/assets/scss/styles.scss";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

const isDevelopment = import.meta.env.VITE_ENV === "development";
if (isDevelopment) {
  console.log("Running in development mode");
} else {
  console.log("Running in production mode");
}

// Use Bootstrap
app.use(createBootstrap());

// Use Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

// Use Router
app.use(router);

// Use FontAwesome
library.add(
  fasArrowUp,
  fasXmark,
  fasRotateRight,
  farPaperPlane,
  fasHurricane,
  fasTornado,
);

// Setup axios to use a base URL of the API from the VITE_API_URL environment variable
// or default to http://localhost:8001
axios.defaults.baseURL =
  import.meta.env.VITE_API_URL || "http://localhost:8001";

app.component("font-awesome-icon", FontAwesomeIcon).mount("#app");
