import { ref, Ref } from "vue";
import { defineStore } from "pinia";

import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";

const breakpoints = useBreakpoints(breakpointsBootstrapV5);

export const useAppStore = defineStore("appStore", () => {
  // State
  const iframeParentUrl: Ref<string> = ref(
    import.meta.env.VITE_IFRAME_PARENT_URL || "http://127.0.0.1:5500",
  );

  const isMobile: Ref<boolean> = breakpoints.smaller("md");
  const isTablet: Ref<boolean> = breakpoints.between("md", "lg");

  return {
    iframeParentUrl,
    isMobile,
    isTablet,
  };
});
