<script setup lang="ts">
import { onBeforeUnmount, onMounted, Ref, ref } from "vue";
import { storeToRefs } from "pinia";
import type { BCarousel } from "bootstrap-vue-next";
import { MediaType } from "@/types/mediaTypes";
import { useProcessStore } from "@/stores/process";

const processStore = useProcessStore();
const {
  mediaItems,
  mediaItemIndex,
  mediaGenerationInProgress,
  enableAutoSlide,
} = storeToRefs(processStore);
const { setMediaItemIndex, setEnableAutoSlide } = processStore;

const carousel: Ref<InstanceType<typeof BCarousel> | null> = ref(null);
const autoSlideIntervalTime: number = 2000;
let autoSlideTimer: ReturnType<typeof setTimeout> | null = null;

/**
 * Automatically transitions to the next slide in the carousel after a set
 * interval if the next image is available.
 */
function autoSlide() {
  if (enableAutoSlide.value) {
    if (mediaItemIndex.value < mediaItems.value.length - 1) {
      const nextMediaItemIndex = mediaItemIndex.value + 1;
      if (mediaItems.value[nextMediaItemIndex] !== null) {
        setMediaItemIndex(mediaItemIndex.value + 1);
      }
      if (autoSlideTimer !== null) {
        autoSlideTimer = setTimeout(autoSlide, autoSlideIntervalTime);
        console.log(
          `Waiting ${autoSlideIntervalTime / 1000} secs before attempting to slide to the next image..`,
        );
      }
    } else if (mediaItemIndex.value === mediaItems.value.length - 1) {
      stopAutoSlide();
    }
  } else {
    stopAutoSlide();
  }
}

/**
 * Stops the automatic slide transition in the carousel.
 */
function stopAutoSlide() {
  if (carousel.value) {
    carousel.value.pause();
    if (autoSlideTimer) {
      clearTimeout(autoSlideTimer);
      autoSlideTimer = null;
      console.log("Stopping carousel");
    }
  }
}

onMounted(async () => {
  setEnableAutoSlide(true);
  autoSlideTimer = setTimeout(autoSlide, autoSlideIntervalTime);
});

onBeforeUnmount(() => {
  if (autoSlideTimer) {
    clearTimeout(autoSlideTimer);
    autoSlideTimer = null;
  }
});
</script>

<template>
  <b-carousel
    ref="carousel"
    v-model="mediaItemIndex"
    :controls="mediaItems.length > 1"
    indicators
    no-wrap
    img-width="400"
    img-height="400"
    @click="stopAutoSlide"
  >
    <b-carousel-slide
      v-for="(mediaItem, index) in mediaItems"
      :key="index"
      :img-blank="!mediaItem"
    >
      <template #img>
        <div v-if="mediaItem?.type === MediaType.ImageJPEG">
          <img
            class="d-block img-fluid w-100"
            width="400"
            height="400"
            :src="mediaItem?.src"
          />
        </div>
        <div v-else-if="mediaItem?.type === MediaType.VideoMP4">
          <div class="ratio ratio-1x1">
            <video controls muted autoplay loop>
              <source :src="mediaItem?.src" :type="MediaType.VideoMP4" />
              Your browser does not support videos.
            </video>
          </div>
        </div>
      </template>

      <div v-if="mediaGenerationInProgress"><b-spinner></b-spinner></div>
    </b-carousel-slide>
  </b-carousel>
</template>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

::v-deep(.carousel) {
  max-width: 400px;
  margin: 0 auto;

  .carousel-control-prev {
    opacity: 1;

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.354a.5.5 0 0 1 0 .707L5.207 8l6.147 5.939a.5.5 0 1 1-.708.708l-6.5-6.5a.5.5 0 0 1 0-.708l6.5-6.5a.5.5 0 0 1 .707 0z'/%3E%3C/svg%3E");
      background-color: $brand-primary;
      opacity: 1;
      margin-left: -3rem;
      padding: 1rem 1rem;
      border: $brand-primary solid 5px;
    }
  }

  .carousel-control-next {
    opacity: 1;

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 14.646a.5.5 0 0 1 0-.707L10.793 8 4.646 2.061a.5.5 0 1 1 .708-.708l6.5 6.5a.5.5 0 0 1 0 .708l-6.5 6.5a.5.5 0 0 1-.708 0z'/%3E%3C/svg%3E");
      background-color: $brand-primary;
      opacity: 1;
      margin-right: -3rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding: 1rem 1rem;
      border: $brand-primary solid 5px;
    }
  }
}
</style>
